import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../img/logos/logo-main-2.png'
function NavBar() {
  return (

    <>
      <div class="mx-lg-4">
        <header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-1 mt-3 mt-lg-1">
          <a href="/" class="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
            <img src={logo} alt="" className="img-nav d-inline-block align-text-top " />
          </a>

          <ul class="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 gap-2">
            <li><Link to='/' class="nav-link px-2 link-dark">Inicio</Link></li>
            <li><Link to='/smartpay' class="nav-link px-2 link-dark">Smart Pay</Link></li>
            <li><Link to='/bella' class="nav-link px-2 link-dark">BellaBot</Link></li>
            <li><Link to='/blogs' class="nav-link px-2 link-dark">Casos de Éxito</Link></li>
            <li><Link to='/contact' class="btn btn-primary btn-sm mt-1 rounded-pill px-4 ">Contácto</Link></li>
          </ul>
          {/* <div class="col-md-3 text-end">
        <button type="button" class="btn btn-outline-primary me-2">Login</button>
        <button type="button" class="btn btn-primary">Sign-up</button>
      </div> */}
        </header>
      </div>
    </>
  )
}

export default NavBar