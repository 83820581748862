import React from 'react'
//Icons 
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaInstagram, FaTiktok, FaFacebookF, FaYoutube,FaTwitter } from 'react-icons/fa';
import footerlogo from '../img/logos/logo-3.png'

function Footer() {
    return (
        <>
            <div className="container-fluid bg-dark text-light footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-2">
                        <div className="col-lg-3 col-md-6">
                            <div className="position-relative mt-5 w-100">
                                <img src={footerlogo} className="img-logo-sp img-fluid d-block mx-auto" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-white mb-4">Nuestra oficina</h4>
                            <p className="mb-2"><FaMapMarkerAlt /> Transístmica, Av. 7ma. C Nte., Panamá</p>
                            <p className="mb-2"> <FaPhoneAlt /> +507 830-5248</p>
                            <p className="mb-2"><FaEnvelope />  info@smart-retail-group.com</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-white mb-4">Servicios</h4>
                            <a className="btn btn-link text-decoration-none" href="">Venta y promoción</a>
                            <a className="btn btn-link text-decoration-none" href="">Recepción de pagos</a>
                            <a className="btn btn-link text-decoration-none" href="">Atención al cliente</a>
                        </div>
                        <div className="col-lg-3 col-md-6 text-decoration-none">
                            <h4 className="text-white mb-4 text-decoration-none">Enlaces rápidos</h4>
                            <a className="btn btn-link text-decoration-none" href="">Sobre nosotros</a>
                            <a className="btn btn-link text-decoration-none" href="">Contáctanos</a>
                            <a className="btn btn-link text-decoration-none" href="">Nuestros servicios</a>
                        </div>

                        <div className='col-12 text-center'>
                        <div className=" pt-2">
                                <a className="btn btn-square btn-outline-light rounded-circle me-2" href="https://www.instagram.com/bellabellabot/?hl=es">
                                   <div className=' pb-1' >
                                    <FaInstagram  />
                                   </div>
                              
                                </a>
                                <a className="btn btn-square btn-outline-light rounded-circle me-2" href="https://www.tiktok.com/@bellabellabot">
                                <div className=' pb-1' >
                                <FaTiktok />
                                   </div>
                                </a>
                                <a className="btn btn-square btn-outline-light rounded-circle me-2" href="https://www.youtube.com/channel/UCwB3m6oEiDbZoa0FziloePg">                                    
                                    <div className=' pb-1' >
                                        <FaYoutube />
                                   </div>
                                </a>                     
                           
                                <a className="btn btn-square btn-outline-light rounded-circle me-2" href="https://www.facebook.com/profile.php?id=100088736221222">
                                  <div className=' pb-1' >
                                    <FaFacebookF />
                                   </div>
                                </a>

                                <a className="btn btn-square btn-outline-light rounded-circle me-2" href="https://twitter.com/BellabellaBot?t=VGcCwsEtH0KO9wQRedN-GA&s=09">
                                  <div className=' pb-1' >
                                    <FaTwitter />
                                   </div>
                                </a>
                            </div> 
                        </div>

                    </div>
                </div>
            </div>
            <div className="container-fluid copyright py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center  text-white mb-3 mb-md-0">
                            &copy; <a className="text-decoration-none text-white" href="/">Smart Retail Group</a> Todos los derechos reservados.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer