import React from 'react'

function BlogAside() {
  return (
    <>
      <div class="col-md-4">
        <div class="position-sticky">
          <div class="p-4 mb-3 bg-primary rounded text-white">
            <h4 class="fst-italic text-white">Smart Retail Group </h4>
            <p class="mb-0">Fidelice clientes, Empodere a sus empleados y optimice la operación.</p>
          </div>



          {/* <div class="p-4">
          <h4 class="fst-italic">Archives</h4>
          <ol class="list-unstyled mb-0">
            <li><a href="#"></a></li>
 
          </ol>
        </div>

        <div class="p-4">
          <h4 class="fst-italic">Elsewhere</h4>
          <ol class="list-unstyled">
            <li><a href="#">GitHub</a></li>
            <li><a href="#">Twitter</a></li>
            <li><a href="#">Facebook</a></li>
          </ol>
        </div> */}
        </div>
      </div>
    </>
  )
}

export default BlogAside