import React from 'react'
import Hero from './Hero/Hero'
import Features from './Features/Features'
import Banner from './Hero/Banner'
import Left from './Sections/Left'
import Right from './Sections/Right'
import Image_full from './Sections/Image_full'
function _index() {
  return (

    <>
      <Banner></Banner>     
      <Hero></Hero>
      <Features></Features>
      <Image_full></Image_full>
   </>

  )
}

export default _index