import React from 'react'
import Bella_1 from './img/product/bella_1.png'
function About() {
  return (
    <>
		<section id="about" className="about-section received-section pt-15">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="about-img received-img wow fadeInUp" data-wow-delay=".5s">
							<img src={ Bella_1} alt=""/>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="about-content received-content">
							<div className="section-title">
								{/* <span className="wow fadeInUp"data-wow-delay=".2s">Smart Retail Group</span> */}
								<h2 className="h1 gradiant-bella fw-bold mb-25" >¿Quién es BellaBot?</h2>
								<p className="wow fadeInUp">
									Es el primer robot de
									 prestación de servicios completamente dimensional con 
									 sensores de Pudu Robotics,
									 <span className='gradiant-bella '> BellaBot </span>  
									 está equipado con 
									 un sistema de interacción multimodal,traído a Panamá por &nbsp;
									 <strong>
									 	 Smart Retail Group
									 </strong>.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    </>
  )
}

export default About