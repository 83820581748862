import React from 'react'
function BlogSection() {
  return (
    <>  
      <div class="p-4 p-md-5 mb-4 mt-4 img-blog rounded text-bg-dark border shadow">
          <div class="col-md-6 px-0 my-5">
            <h1 class="fw-bold text-white">Casos de éxitos </h1>
            {/* <p class="text-muted my-3"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi alias earum molestiae omnis incidunt doloribus odio repellat deleniti magnam maiores obcaecati eum nobis nam provident minus quod cumque, consectetur praesentium.</p> */}
            {/* <p class="lead mb-0"><a href="#" class="text-white fw-bold">Continue reading...</a></p> */}
          </div>
      </div>    
    </>
  )
}

export default BlogSection