import React from 'react'

function Features(data) {

console.log(data);
  return (
    <>
        <section class="bg-gray features-icons text-center my-5 py-5">
            <div class="container">
                <div class="row">
                <div class="col-lg-4">                    
                        <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                            <div class="features-icons-icon d-flex"><div class="m-auto py-3 ">
                            <img src={data.data.ImgFeaters1}   alt="..." style={{width:100}} /> </div></div>
                            <h3 class="gradiant-sp">{data.data.TitleFeaters1}</h3>
                            <p class="text-muted mb-0">{data.data.DecriptionFeaters1}</p>
                        </div>
                    </div>
                    <div class="col-lg-4">                    
                        <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                            <div class="features-icons-icon d-flex"><div class="m-auto py-3">
                            <img src={data.data.ImgFeaters2} alt="..." style={{width:100}} /> </div></div>
                            <h3 class="gradiant-sp">{data.data.TitleFeaters2}</h3>
                            <p class="text-muted mb-0">{data.data.DecriptionFeaters2}</p>
                        </div>
                    </div>
                    <div class="col-lg-4">                    
                        <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                            <div class="features-icons-icon d-flex"><div class="m-auto py-3">
                            <img src={data.data.ImgFeaters3} alt="..."  style={{width:100}}/> </div></div>
                            <h3 class="gradiant-sp">{data.data.TitleFeaters3}</h3>
                            <p class="text-muted mb-0">{data.data.DecriptionFeaters3}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
    
    </>
  )
}

export default Features