import React from 'react'
import { BrowserRouter as Router, Route, Routes, useNavigate} from "react-router-dom";
//Components
import LandingPage from '../components/_index';
import Landing_Bella from '../components/_index_2';
//Pages
import Page_Example from "../components/Page_Example";
import SmartRetailPage from "../components/SmartRetail/SmartRetailPage";
import SmartPay from "../components/Smartpay/Smartpay";
import Blogs from "../components/Blogs/index";
import NavBar from "../components/NavBar/NavBar";
import Contact from "../components/Contact/Contact";

function MainRoutes() {
   return (
      <>
         <Router>
            <NavBar></NavBar>
            <Routes>
               {/* Index */}
               <Route exact path="/" element={<SmartRetailPage />} />
               <Route path="/bella" element={<Landing_Bella />} />
               <Route path="/blogs" element={<Blogs></Blogs>} />
               <Route path="/smartpay" element={<SmartPay></SmartPay>} />
               <Route path="/smartretail" element={<SmartRetailPage />} />
               <Route path="/contact" element={<Contact />} />
               {/* No Match link */}
               {/* <Route path="/example_page" element={<Page_Example/>}/>        */}
               {/* <Route path="*" element={<NoMatch />} /> */}
            </Routes>
         </Router>
      </>
   )
}

export default MainRoutes