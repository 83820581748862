import React from 'react'
//Components 
import NavBar from '../NavBar/NavBar';
import RightBanner from '../Hero/RightBanner';
import Left from '../Sections/Left';
import LeftCarrusel from '../Sections/LeftCarrusel';
import RightCarrusel from '../Sections/RightCarrusel';
import Slider from '../Slider/Slider'
import ContactForm from '../Forms/ContactForm';
import Footer from '../Footer/Footer';
import Carousel from 'react-bootstrap/Carousel';

//CSS
import './SmartRetailStyle.css';
//Images
import bellaimg from '../../components/Bella/img/bella.png';
import smartpay from '../../components/Smartpay/img/smartpay.png';
import muvend from '../../components/img/products/vending.png'
import products from '../img/products/srg-products.png'
import allproduct from '../img/products/allproduct.png'
import BellaFace from '../img/products/face.png'


function SmartRetailPage() {
    const page = 'Smart Retail Group';
    const titlePage = 'Transformación digital';
    const description = 'Fidelice clientes, Empodere a sus empleados y optimice la operación'
    const sliderContent = [
        {
            title: 'Venta y promoción',
            description: 'El proceso de venta y promoción de productos siempre debe estar disponible para el cliente y podemos lograrlo con nuestro equipo de ventas MuVend.',
            image: muvend,
        },
        {
            title: 'Recepcion de pagos',
            description: 'Recibe e identifica la transaccion realizada en tu negocio de la forma más fácil, rápida, automatizada y versátil a traves de SmartPay brindando la facilidad y accesibilidad tus clientes de pagar de la forma mas comoda posible para ellos.',
            image: smartpay,
            buttonLink: '/smartpay'

        },
        {
            title: 'Atencion al cliente',
            description: 'Establece puntos de contacto con tu cliente, a traves de BellaBot para establecer relaciones con ellos, durante y después de la venta.',
            image: bellaimg,
            buttonLink: '/bella'
        },

    ]
    return (
        <>

            <RightBanner
                bannerImage={allproduct}
                pageName={page}
                titlePage={titlePage}
                description={description} 
                buttonLink={"#contact_form"}
                />

            <Left title="!Hola soy Bella!" description="¿Quieres más información? " image={BellaFace} buttonLink={"/bella"} />

            <div className="px-4 pt-5 pb-5  text-center secciontion_container">
                <h2 className="text-white fw-bold">Potencia tú negocio </h2>
                <div className="col-lg-6 mx-auto">
                    <p className="text-white mb-4 h5">La simplificación de procesos y obtención de
                        información dentro de la empresa aumenta la productividad y reduce los tiempos de respuesta,
                        a continuación mostramos las formas de hacerlo: </p>
                </div>
                {/*<div className="overflow-hidden" >
                    <div className="container px-5">

                       
                        <Slider content={sliderContent} />
                    </div>
                </div>*/}
            </div>

            <Carousel interval={5000}>
                <Carousel.Item>
                    <LeftCarrusel title="Venta y promoción" description="El proceso de venta y promoción de productos siempre debe estar disponible para el cliente y podemos lograrlo con nuestro equipo de ventas MuVend." image={muvend} />
                </Carousel.Item>
                <Carousel.Item>
                    <LeftCarrusel title="Recepcion de pagos" description="Recibe e identifica la transaccion realizada en tu negocio de la forma más fácil, rápida, automatizada y versátil a traves de SmartPay brindando la facilidad y accesibilidad tus clientes de pagar de la forma mas comoda posible para ellos." image={smartpay} buttonLink={"/smartpay"} />
                </Carousel.Item>
                <Carousel.Item>
                    <LeftCarrusel title="Atencion al cliente" description="Establece puntos de contacto con tu cliente, a traves de BellaBot para establecer relaciones con ellos, durante y después de la venta." image={bellaimg} buttonLink={"/bella"} />
                </Carousel.Item>
            </Carousel>

            <div className="container_smartpay" >
                <ContactForm />
            </div>

            <Footer />


        </>
    )
}

export default SmartRetailPage