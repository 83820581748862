import React from 'react'
//Utils 
import { RenderIf } from '../../utils/RenderIf'


function RightBanner({ bannerImage, pageName, titlePage, description, buttonLink }) {
    return (
        <>

            <div className="main-banner mt-lg-5 mt-4 " id="top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="left-content show-up header-text" >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h6>{pageName} </h6>
                                                <h2 className='gradiant-srg' >{titlePage}</h2>
                                                <p className='text-muted h4'>{description}</p>
                                            </div>
                                            {/** Button */}
                                            <RenderIf isTrue={buttonLink === '' || buttonLink !== undefined}>
                                                <div className="col-lg-12">
                                                    <div className="border-first-button scroll-to-section">
                                                        <a className='btn btn-primary py-2 px-4 mt-4' href={buttonLink}>Contáctanos</a>
                                                    </div>
                                                </div>
                                            </RenderIf>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 align-self-center ">
                                    <div className="right-image" >
                                        <img src={bannerImage}  className='img-fluid rounded mt-5 mb-5 mb-lg-0'/>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default RightBanner