import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Bella_1 from './img/product/bella_3.png'
import Logo from './img/logo/logo-main.png'
function Hero() {
    return (
        <>
            <section id="home" className="hero-section border">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="hero-content">
                                <h1 className="hero-title gradiant-bella ">BellaBot</h1>
                                <p className="wow fadeInUp">
                                    Un robot de entrega innovador para tu restaurante
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="hero-img animate__animated  animate__backInUp " data-wow-delay=".5s">
                                <img src={Bella_1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero