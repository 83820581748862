import React from 'react'
import { useState } from 'react'


//Utils 
import { RenderIf } from '../../utils/RenderIf';
//External components 
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';
//CSS 
import './ContactFormStyles.module.css';

function ContactForm() {
    const [productSelected, setProductSelected] = useState();
    const [captchaValue, setCaptchaValue] = useState(null);

    //Function to change the value of the captcha 
    const changeCaptchaValue = (value) => {
        if (value !== null) {
            setCaptchaValue(value)
        } else {
            setCaptchaValue(null)
        }
    }

    //FUnction to send the email 
    const sendEmail = async (e) => {
        e.preventDefault();
        //We check the captcha has value 
        if (captchaValue == null) {
            Swal.fire({
                icon: 'error',
                title: 'Complete el captcha'
            })
        } else {
            try {
                const form = new FormData(e.target);
                let contactData = Object.fromEntries(form.entries());
                //We check the contact fields form
                //We check if the user selec the other option 
                if (contactData.product_select === 'Otros' && contactData.comments === '') {
                    Swal.fire({
                        icon: 'error',
                        title: 'Describa que tipo de demo requiere en el campo de comentarios'
                    });
                } else if (contactData.product_select === 'null') {
                    Swal.fire({
                        icon: 'error',
                        title: 'Seleccione el tipo de demo que desea requerir'
                    });
                } else {
                    //We send the email
                    await emailjs.sendForm('service_ky98uck', 'template_j2zq5gk', e.target, 'wR2F4askvCxnVFyR1')
                    Swal.fire({
                        icon: 'success',
                        title: 'Correo enviado'
                    });
                    //We clean the form 
                    cleanForm();
                }

            } catch (err) {
                console.log(err);
                Swal.fire({
                    icon: 'error',
                    title: 'Error desconocido'
                });
            }
        }

    }

    //Function to clean the form 
    const cleanForm = () => {
        //We check the comments field 
        if( productSelected === 'Otros' && document.getElementById('comments').value !== ''){ 
            document.getElementById('comments').value = '';
        }
        //We get the values 
        document.getElementById('product-select').value = 'null';
        document.getElementById('name').value = '';
        document.getElementById('mail').value = '';
        document.getElementById('businessName').value = '';
        document.getElementById('phone').value = '';
    }

    return (
        <>
            <div className="container px-2 px-lg-5 py-5">
                <div className="row justify-content-center">
                    <div className='col-lg-7'>
                        <div className="bg-white border rounded p-4 p-sm-5" >
                            <div className="text-center mx-auto" >
                                <p className="d-inline-block text-primary fw-semi-bold py-1 px-3">Contáctanos
                                </p>
                                <h2 className="mb-5">¿Cómo podemos ayudarte?</h2>
                            </div>
                            <form className="row g-3" onSubmit={sendEmail} >
                                <div className="col-sm-12">
                                    <div >
                                        <select required className="form-select p-3" name='product_select' onChange={(e) => setProductSelected(e.target.value)} id="product-select" aria-label="Default select example">
                                            <option value='null' selected>Seleccione...</option>
                                            <option value="Todos">Todos</option>
                                            <option value="Venta y promoción">Venta y promoción</option>
                                            <option value="Recepción de pagos">Recepción de pagos</option>
                                            <option value="Atención al cliente">Atención al cliente</option>
                                            <option value="Otros">Otros (Utilice el campo de comentarios para indicar)</option>
                                        </select>
                                    </div>
                                </div>
                                <RenderIf isTrue={productSelected === "Otros"}>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" placeholder="Leave a message here" name='comments' id="comments" ></textarea>
                                            <label htmlFor="message">Comentarios</label>
                                        </div>
                                    </div>
                                </RenderIf>
                                <div className="col-sm-6">
                                    <div className="form-floating">
                                        <input required type="text" className="form-control" id="name" name='name' placeholder="Nombre" />
                                        <label htmlFor="name">Nombre</label>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-floating">
                                        <input required type="email" className="form-control" id="mail" name='email' placeholder="Correo" />
                                        <label htmlFor="mail">Correo electrónico </label>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-floating">
                                        <input required type="text" className="form-control" id="businessName" name='business_name' placeholder="Nombre de la empresa" />
                                        <label htmlFor="businessName">Nombre de la empresa</label>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-floating">
                                        <input required type="text" className="form-control" id="phone" name='phone' placeholder="Telefono" />
                                        <label htmlFor="phone">Teléfono</label>
                                    </div>
                                </div>
                                <div className="recaptcha">
                                    <ReCAPTCHA
                                        sitekey='6LcysW0jAAAAAMQJV-H_TtTVDnWQFYBBMtPBAvKr'
                                        onChange={changeCaptchaValue}
                                    >
                                    </ReCAPTCHA>
                                </div>
                                <div className="col-12 text-center">
                                    <button className="btn btn-primary w-100 py-3" type="submit"> Enviar </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactForm