import React from 'react'
import Container from 'react-bootstrap/Container';
import BlogSection from './BlogSection'
import BlogSlider from './BlogSlider'
import BlogContent from './BlogContent'
import BlogAside from './BlogAside'

import Footer from '../Footer/Footer';


//Publications
import { publications } from './data';


// const publications= [
//   {
//     title: '',
//     author:'',
//     date_publication:'',
//     description: '',
//     content:'',
//     image:'',

//   },
// ];

function Blogs() {
  return ( 
    <>
      <Container>      
        {/* <BlogSlider /> */}
        <BlogContent data={publications}  /> 
      </Container>
      <Footer />
    </>
  )
}

export default Blogs