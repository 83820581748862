import React from 'react'
import HeroImage from './img/bg-sp.png'
function Hero() {
    return (
        <>
            {/* <section id="home" className="hero-section-sp bg-gradiante-sp ">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="hero-content-sp">
                                <h1 className="text-white">Smart Pay</h1>
                                <p className="wow fadeInUp">
                                    Un robot de entrega innovador para tu restaurante
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="hero-img-sp animate__animated  animate__backInUp " data-wow-delay=".5s">
                                <img src={HeroImage} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <div class=" px-4 mt-5 rounded bg-gray">
                <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div class="col-10 col-sm-8 col-lg-5 text-center">
                        <img src={HeroImage} class="d-block mx-lg-auto rounded img-fluid" alt="Maquina de Smart Pay" width="700" height="500" loading="lazy" />
                    </div>
                    <div class="col-lg-7">
                        <h1 class="display-4 fw-bold lh-1 mb-3 gradiant-sp ">Transforma y automatiza tu negocio</h1>
                        <h4 class="text-muted " >Agiliza los procesos de atención al cliente, brinda mejor experiencia en calidad de servicio al cliente y optimiza tu operación administrativa. </h4>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero