module.exports = {
   publications: [

      {
         // *Header Post
         title: 'Titulo 1',
         author: 'Yoonose Holku',
         date_publication: '12/34/67',
         description_short: 'Prueba de contenido blog ',
         // *Image Post
         image: 'Aqui va una imgen',
         // *Card pre viwew
         card_view: {
            image: 'Aqui va una imgen',
            paragrph: 'Aqui va una imgen',
         },
         // *Post Content 
         content_post: {
            image: 'Aqui va una imgen',
            paragrph: 'Aqui va un parrafo ',
         },

      },
   ],

};





