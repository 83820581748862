import React from 'react'
import ContactForm from '../Forms/ContactForm'
import Footer from '../Footer/Footer';
function Contact() {
    return (
        <>
            <div className="container_smartpay" >
                <ContactForm />
            </div>
            <Footer />
        </>
    )
}
export default Contact