import React from 'react'


//css
import './Smartpay.css'

//Componenetes 
import Hero from './Hero'
//Others
import Right from '../Sections/Right'
import Left from '../Sections/Left'
import Center from '../Sections/Center'
import Features from '../Features/Features'
import Card from '../Card/Card'
// import Hero from '../Hero/Hero'
import LeftBanner from '../Hero/LeftBanner'
import Slider from '../Slider/Slider'
import Footer from '../Footer/Footer'
import ContactForm from '../Forms/ContactForm'
import LeftCarrusel from '../Sections/LeftCarrusel';
import Carousel from 'react-bootstrap/Carousel';

//Importaicones de imagenes 1 
import imageLogo from '../img/imgSmartpay/logo.png'
import imgSmartpay from '../img/imgSmartpay/smartpay.png'
import SmartPayAll from '../img/imgSmartpay/SmartPayAll.png'
import ProcesadorDePago from '../img/imgSmartpay/ProcesadorDePago.png'
import Cajero from '../img/imgSmartpay/Cajero.png'
import ScanAndGo from '../img/imgSmartpay/ScanAndGo.png'
import AllSmartpayProduct from '../img/imgSmartpay/AllSmartpayProduct.png'
import AllproductList from "../img/imgSmartpay/allProductList.png"
import Gestion from "../img/imgSmartpay/Gestion.png"
import Innovacion from "../img/imgSmartpay/Innovacion.png"
import Automatizacion from "../img/imgSmartpay/Automatizacion.png"
import Nequi from "../img/imgSmartpay/nequi.png"
import Efectivo from "../img/imgSmartpay/efectivo.png"
import Tarjeta from "../img/imgSmartpay/tarjeta.png"
import Yappy from "../img/imgSmartpay/yappy.png"

function Smartpay() {


    const title_smartpay = "¿Qué es smartPay?"
    const descripcion_smartpay = "Es una plataforma de autoservicio que facilita y automatiza el proceso de selección y pago de productos, orientado a la asistencia de operación a cualquier tipo de negocio."
    const LeftBannerTitle = "Transforma y automatiza tu negocio"
    const LeftBannerDescription = "Agiliza los procesos de atención al cliente, brinda mejor experiencia en calidad de servicio al cliente y optimiza tu operación administrativa."
    const AutosercioTitle = "Autoservicio"
    const DescriptionAutoservicio = "El módulo de autoservicio permite que el cliente se auto atienda, con la posibilidad de seleccionar el producto y realizar el pago de forma autónoma. Este módulo está pensado para cualquier esquema de negocio prepago, como por ejemplo restaurantes de comida rápida, con la posibilidad de publicitar y promocionar productos, Este módulo permite registrar un catálogo de productos con inventario y gestionar sus ventas."
    const CardDescription1 = "El módulo de autoservecio, permite que el cliente se auto atienda..."

    const TitleProcesadorPago = "Procesador de pago"
    const DescriptionProcesadorDePago = "El módulo de Procesador de pago, permite al negocio centralizar todos los métodos de pago como (Efectivo, tarjetas de crédito o débito, Yappy, Nequi, etc)  en una sola plataforma, el cual también permite tener métricas de los ingresos he historial transaccional. "
    const CardDescription2 = "El módulo de Procesador de pago permite al negocio centralizar todos los métodos de pago. "
    const CajetoTitle = "Cajero"
    const DescriptionCajero = "El módulo de cajero ayuda a gestionar de forma más eficiente el cobro de los productos registrados en el catálogo, a través de una interfaz intuitiva y versátil."
    const CardDescription3 = "El módulo de cajero ayuda a gestionar de forma más eficiente el cobro de los productos registrados en el catálogo, a través de una interfaz intuitiva y versátil."
    const ScanAndGoTitle = "Scan and go"
    const CardDescription4 = "El módulo de Scan and go, es una variante de autoservicio pero su enfoque está en el retail..."
    const data = { TitleFeaters1: "Automatización", DecriptionFeaters1: "Operaciones más simple y más rápida", ImgFeaters1: Innovacion, TitleFeaters2: "Innovación", DecriptionFeaters2: "Crea una nueva experiencia tecnológica", ImgFeaters2: Automatizacion, TitleFeaters3: "Gestión", DecriptionFeaters3: "Administra toda la operación con datos en tiempo real", ImgFeaters3: Gestion }
    const sliderContent = [
        {
            title: AutosercioTitle,
            description: CardDescription1,
            image: SmartPayAll
        },
        {
            title: TitleProcesadorPago,
            description: CardDescription2,
            image: ProcesadorDePago
        },
        {
            title: CajetoTitle,
            description: CardDescription3,
            image: Cajero
        },
        {
            title: ScanAndGoTitle,
            description: CardDescription3,
            image: ScanAndGo
        },

    ]

    return (

        <>
            <main className='main-section'>
                {/* //Enviado de contenido a componente  */}

                <Hero></Hero>             
                {/* <LeftBanner bannerImage={imgSmartpay} pageName={"Smartpay"} titlePage={LeftBannerTitle} description={LeftBannerDescription}></LeftBanner> */}
                <Center title={title_smartpay} description={descripcion_smartpay} image={AllproductList} ></Center>
                <Features data={data}></Features>
                {/* , */}
                <section className="py-5 " id="scroll-target">
                    <div className="container px-5 my-5">
                        <div className="row gx-5 align-items-center">

                            <div className="row">
                                <div className="col-lg-12">
                                    <h1 className="fw-bolder gradiant-sp m-3 text-center">Módulos de SmartPay</h1>
                                    <p className='text-center lead fw-normal text-muted mb-0'>SmartPay se adapta a la necesidad de tu negocio con el objetivo de mejorar la experiencia del cliente y la capacidad de poder administrar tu negocio de forma eficiente y automatizada.</p>
                                </div>
                            </div>
                            {/*<Slider content={sliderContent}></Slider>*/}


                        </div>

                    </div>
                    <Carousel interval={5000}>
                        <Carousel.Item>
                            <LeftCarrusel title={AutosercioTitle} description={CardDescription1} image={SmartPayAll} />
                        </Carousel.Item>
                        <Carousel.Item>
                            <LeftCarrusel title={TitleProcesadorPago} description={CardDescription2} image={ProcesadorDePago} />
                        </Carousel.Item>
                        <Carousel.Item>
                            <LeftCarrusel title={CajetoTitle} description={CardDescription3} image={Cajero} />
                        </Carousel.Item>
                        <Carousel.Item>
                            <LeftCarrusel title={ScanAndGoTitle} description={CardDescription3} image={ScanAndGo} />
                        </Carousel.Item>
                    </Carousel>
                </section>


                <section className=" " id="scroll-target">
                    <div className="container px-5  my-5">
                        <div className="row gx-5 align-items-center">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h1 className="fw-bolder gradiant-sp m-3 text-center">Esquemas de pago</h1>
                                    <p className='text-center lead fw-normal text-muted mb-0'>SmartPay implementa metodos de pago centralizados en todos sus modulos</p>
                                </div>
                            </div>

                            <div className="col-md-3 mt-5 text-center">
                                <img src={Efectivo} style={{ width: 150 }}></img>
                            </div>
                            <div className="col-md-3 mt-5 text-center">
                                <img src={Tarjeta} style={{ width: 150 }}></img>
                            </div>
                            <div className="col-md-3 mt-5 text-center">
                                <img src={Nequi} style={{ width: 150 }}></img>
                            </div>
                            <div className="col-md-3 mt-5 text-center">
                                <img src={Yappy} style={{ width: 150 }}></img>
                            </div>

                        </div>
                    </div>
                </section>
                <Right title={AutosercioTitle} description={DescriptionAutoservicio} image={SmartPayAll} />
                <Left title={TitleProcesadorPago} description={DescriptionProcesadorDePago} image={ProcesadorDePago} />
                <Right title={CajetoTitle} description={DescriptionCajero} image={Cajero} />
                <Left title={ScanAndGoTitle} description={descripcion_smartpay} image={ScanAndGo} />

                <div className="container_smartpay" >
                    <ContactForm />
                </div>
                <Footer></Footer>

            </main>
        </>
    )
}

export default Smartpay