import React from 'react'
import Right from './Sections/Right'
//Importaicones de imagenes 1 
import image_2 from './img/backgrounds/bg-showcase-2.jpg'
import Left from './Sections/Left'
function Page_Example() {

    //Compponente 1
    //Importaicones de Contenido  
    const title = "Hello guys I'm sending props!!"
    const description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto est, ut esse a labore aliquam beatae expedita. Blanditiis impedit numquam libero molestiae et fugit cupiditate, quibusdam expedita, maiores eaque quisquam." 
    //Importaicones de Contenido 2 por link 
    const image = "https://dummyimage.com/600x400/343a40/6c757d"   
    
    return (
    
    <>  
        <main className='main-section'>
            {/* //Enviado de contenido a componente  */}
            <Right  title={title} description={description} image={image_2 }  />
            <Left   title={title} description={description} image={image_2 }  />    
        </main>      
    </>
  )
}

export default Page_Example