//Main Routes  componenets 
import MainRoutes from './routes/Routes'
//css
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
      <MainRoutes></MainRoutes>
  );
}

export default App;
