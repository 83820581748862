import React from 'react'
import Bella_1 from './img/product/bella_4.png'
function HelpU() {
  return (
    <>
    	<section id="helpu" className="helpu-section pt-15 ">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="helpu-content">
							<div className="section-title">								
								<h2 className="h1 gradiant-bella fw-bold  mb-35">¿ Cómo te ayuda BellaBot?</h2>
								<p className="mb-35 " data-wow-delay=".6s">
                                BellaBot ofrece a los usuarios una experiencia inigualable 
                                con un robot de entrega de alimentos gracias a su lenguaje 
                                de diseño biónico de vanguardia, modelado adorable, 
                                interacción multimodal y muchas otras características únicas.
                                </p>
							</div>
						</div>
					</div>
					<div className="col-lg-6  order-first order-lg-last">
						<div className="helpu-img" data-wow-delay=".5s">
							<img src={Bella_1 } alt=""/>
						</div>
					</div>
				</div>
			</div>
		</section>
    </>
  )
}

export default HelpU