import React, { useState } from 'react';
//Components 
import BlogSection from './BlogSection'
//Bstrp Components 
import Carousel from 'react-bootstrap/Carousel';
//Utils
import { BiCalendarEdit, BiCategoryAlt, BiArrowBack } from 'react-icons/bi';
import { TfiWrite } from 'react-icons/tfi';

import { RenderIf } from "../../utils/RenderIf";

//imgs
import card_banner from '../../assets/img/blogs/post1/bannercard.jpg';
import blog_1 from '../../assets/img/blogs/post1/1.jpg';
import blog_2 from '../../assets/img/blogs/post1/2.jpg';
import blog_3 from '../../assets/img/blogs/post1/3.jpg';
import blog_4 from '../../assets/img/blogs/post1/4.jpg';
//img post 2
import blog_5 from '../../assets/img/blogs/post2/1.jpeg';
import blog_6 from '../../assets/img/blogs/post2/3.jpeg';
import blog_7 from '../../assets/img/blogs/post2/4.jpeg';
import blog_8 from '../../assets/img/blogs/post2/5.jpeg';
//img post 3
import card_banner_3 from '../../assets/img/blogs/post3/1.jpg';
import blog_9 from '../../assets/img/blogs/post3/2.jpg';
import blog_10 from '../../assets/img/blogs/post3/3.jpg';
import blog_11 from '../../assets/img/blogs/post3/4.jpg';

//img post 3
import blog_12 from '../../assets/img/blogs/post4/1.jpeg';
import blog_13 from '../../assets/img/blogs/post4/2.jpg';
import blog_14 from '../../assets/img/blogs/post4/3.jpg';
import blog_15 from '../../assets/img/blogs/post4/4.jpg';
import blog_16 from '../../assets/img/blogs/post4/5.jpeg';
//img post 3
import blog_17 from '../../assets/img/blogs/post5/1.png';
import blog_18 from '../../assets/img/blogs/post5/2.png';
import blog_19 from '../../assets/img/blogs/post5/3.png';
import blog_20 from '../../assets/img/blogs/post5/4.png';
import blog_21 from '../../assets/img/blogs/post5/5.png';

//img post 6
import blog_22 from '../../assets/img/blogs/post6/1.jpg';
import blog_23 from '../../assets/img/blogs/post6/2.jpg';
import blog_24 from '../../assets/img/blogs/post6/3.jpg';
import blog_25 from '../../assets/img/blogs/post6/4.jpg';

//Content
//Post 1 
const paragraph_1 = "La empresa Smart Retail Group, en colaboración con el restaurante Don Lee, implementaron el robot de delivery Bella bot. ";
const paragraph_2 = "Bella es un robot de servicio y asistencia, que permite a los colaboradores ser más productivos y eficientes, consiguiendo así realizar tareas que suelen ser muy repetitivas. Aliviana la carga laboral y permite una interacción mucho más empática, amistosa e interactiva con los clientes. "
const paragraph_3 = "Con el objetivo de mejorar y acelerar el proceso de entrega de alimentos de una de sus sucursales, específicamente hablando de la sucursal de Don lee el Dorado. Bella se encuentra operativa desde la primera semana del mes de diciembre de 2022. Se han podido resaltar variedad de reacciones ante la innovadora implementación del robot, el cual llama la atención gracias a su alta tecnología y su presencia por primera vez en Panamá, por Smart Retail Group, empresa responsable de distribuir a Bella en Panamá.  "
const paragraph_4 = "Uno de los aspectos que más destacan de esta implementación es la interacción que Bella tiene con los clientes, debido a que Bella puede hablar y hacer gestos dependiendo de que está ocurriendo a su alrededor. También implementa sensores que le permiten poder evitar obstáculos mientras lleva el pedido de un cliente, saludando y despidiéndose una vez que se encuentra con el cliente y permitiendo tener interacción táctil con el cliente gracias a sensores táctiles que se ubican en la parte superior."
const paragraph_5 = "Los colaboradores de Don Lee, que ya han tenido la experiencia de usar a Bella, indican que ha sido de gran ayuda, les ha permitido poder agilizar el despacho de los pedidos de una forma fácil, resaltan también, que muchos de los clientes les gusta interactuar con Bella. "
const paragraph_6 = "Es importante enfatizar que bella no sustituye a un colaborador, ya que requiere que le indiquen en que mesa se encuentre el cliente, resaltando que esta innovadora tecnología es una herramienta que ayudara a dar una mejor atención y experiencia a los clientes."
const paragraph_7 = "Próximamente podremos ver a Bella en el resto de las sucursales de Don lee, compartiendo con los clientes, interactuando con los niños y ayudando a los colaboradores de una forma más rápida e innovadora."

//Post 2
const paragraph_post2_1 = "Smart Pay son plataformas de autoservicio, que próximamente estarán en todas las sucursales, les permitieron transformar por completo la experiencia del cliente y reducir significativamente los tiempos de espera mientras, al mismo tiempo, aumentaban la cantidad de pedidos que sus restaurantes podían procesar"
const paragraph_post2_2 = "Llevar a los clientes a las estaciones de autoservicio también les permitió redistribuir al personal detrás del mostrador, abrir el espacio del restaurante y organizar eficientemente la gestión del efectivo que se maneja en el lugar, sin faltantes o sobrantes."
const paragraph_post2_3 = "El personal ahora es mucho más móvil, ayuda a los clientes con sus consultas, mantiene a los restaurantes en su mejor aspecto e incluso ofrece servicio de mesa con el bot Bella Bella. Esto ha mejorado drásticamente la experiencia gastronómica de los clientes con familias jóvenes o aquellos que necesitan un cuidado especial. "

const paragraph_post2_4 = "Ahora, en lugar de intentar hacer cola y mantener un ojo en su mesa, su pedido se puede hacer de forma rápida y sencilla y luego tomar asiento mientras se prepara y entrega la comida."
const paragraph_post2_5 = "Estamos encantados de haber ayudado a transformar el viaje del cliente y la experiencia gastronómica de Don Lee y esperamos continuar nuestro trabajo con el equipo para mantenerlos a la vanguardia de la tecnología y la innovación en el mercado."

//Post 3 
const paragraph_post3_1 = "A mediados de este mes, los miembros de nuestro equipo de Smart Retail Group llegaron al Panamá Convention Center para asistir en La Asamblea de Gobernadores del BID y de BID Invest. ";
const paragraph_post3_2 = "A mediados de este mes, los miembros de nuestro equipo de Smart Retail Group llegaron al Panamá Convention Center para asistir en La Asamblea de Gobernadores del BID y de BID Invest. Como primer gran evento, fue genial estar presente y ofrecer un servicio a una gran variedad de personas."
const paragraph_post3_3 = "En la Asamblea de este año, instalamos nuestras pantallas de autoservicio, Smart Pay, y demostramos nuestra solución completa de tableros de menú digitales integrados, sistema de punto de venta y pantallas digitales. "
const paragraph_post3_4 = "Le permitieron atender a más usuarios a la vez y minimizar la cantidad de tiempo que los lleva realizar un pedido. Esto reduce significativamente las colas, que son un gran obstáculo para los consumidores de hoy, que valoran la velocidad y la comodidad."
const paragraph_post3_5 = "Si bien está claro que los quioscos de autoservicio están revolucionando la industria del servicio rápido. Smart pay, con su diseño amigable, proporciona la solución integrada perfecta para crear un impacto a distancia, minimizando tanto las colas de caja como los tiempos de espera de los clientes, ideal para este tipo de eventos."
//Post 4
const paragraph_post6_1 = "En Smart Retail Goup, nos enorgullece compartir un caso de éxito que ha revolucionado la experiencia de pago en las cajeras del Panama Convention Center: la implementación del innovador sistema Smart Pay Pos."
const paragraph_post6_2 = "En respuesta a la necesidad de agilizar y simplificar el proceso de pago de las cajeras, se decidio implementar el Smart Pay Pos. Este sistema se ha convertido en un verdadero aliado para el centro, permitiéndoles ofrecer un servicio más eficiente y satisfactorio a los clientes."
const paragraph_post6_3 = "Una de las principales fortalezas del Smart Pay Pos es su interfaz gráfica fácil de usar. Nuestro objetivo era asegurarnos de que tanto los empleados como los clientes pudieran navegar por el sistema de manera intuitiva, sin la necesidad de una capacitación extensa."
const paragraph_post6_4 = "Cada negocio es único, y el Panama Convention Center no es una excepción. Con el Smart Pay Post de Pago, hemos logrado adaptar el sistema a sus necesidades específicas. Mediante la personalización de opciones y configuraciones, se han optimizado el flujo de trabajo y los servicios que ofrecen."
const paragraph_post6_5 = "Además, el sistema cuenta con funciones de reportes y análisis, lo que permite obtener información valiosa sobre las transacciones y realizar ajustes estratégicos en función de los datos recopilados."
const paragraph_post6_6 = "La seguridad es una prioridad en el Panama Convention Center, y el Smart Pay Post de Pago ha sido diseñado con eso en mente. El sistema garantiza altos niveles de seguridad, protegiendo tanto la información de nuestros clientes como los datos internos del negocio."
const paragraph_post6_7 = "La implementación del Smart Pay Post de Pago en el Panama Convention Center ha transformado la forma de operar, brindan un servicio de calidad y garantizan una experiencia de pago satisfactoria para los clientes."
//Post 5

function BlogContent() {

    const [showButton, setShowButton] = useState(false);
    const [option, setOption] = useState({
        option: "Principal",
    });

    const showPosts = (option) => {
        setOption({
            option: option,
        });

        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // console.log(publications)
    return (
        <>
            <RenderIf isTrue={option.option === "Principal"}>
                <BlogSection />
                <h3 className='fw-bold'> Casos más recientes</h3>
                <div class="row mb-2">
                    <div class="col-md-6">
                        <div class="row g-0 border border-warning border-3 rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                            <div class="col p-4 d-flex flex-column position-static">
                                <strong class="d-inline-block mb-2 text-success">Smart Pay</strong>
                                <h5 class="mb-0 fw-bold">Implementación de Smart Pay Post de Pago en el Panama Convention Center</h5>
                                <p class="mb-auto">
                                    En Smart Retail Group, nos enorgullece compartir un caso de éxito
                                    que ha revolucionado la experiencia de pago en las cajeras del
                                    Panama Convention Center: la implementación del innovador
                                    sistema Smart Pay Pos.
                                </p>
                                <a
                                    class="stretched-link"
                                    onClick={() => showPosts("Post6")}
                                    style={{ display: showButton }}
                                >
                                    Continuar leyendo
                                </a>
                            </div>
                            <div class="col-auto d-none d-lg-block">
                                <img src={blog_23} class="bd-placeholder-img img-blog-banner h-100" alt="..." width="200" height="250" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                            <div class="col p-4 d-flex flex-column position-static">
                                <strong class="d-inline-block mb-2 text-primary">BellaBellaBot</strong>
                                <h3 class="mb-0">BellaBella Bot llego a Burger King</h3>
                                <p class="mb-auto">El robot de delivery BellaBot ha sido implementado en la sucursal de Burger King en El Dorado, en colaboración con Smart Retail Group.</p>
                                <a
                                    class="stretched-link"
                                    onClick={() => showPosts("Post5")}
                                    style={{ display: showButton }}
                                >
                                    Continuar leyendo
                                </a>
                            </div>
                            <div class="col-auto d-none d-lg-block">
                                <img src={blog_21} class="bd-placeholder-img img-blog-banner h-100" alt="..." width="200" height="250" />
                            </div>
                        </div>
                    </div>

                </div>
                <h3 className='fw-bold'>Otros Casos</h3>

                <div class="row">
                    <div class="col-md-6">
                        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                            <div class="col p-4 d-flex flex-column position-static">
                                <strong class="d-inline-block mb-2 text-success">Smart Pay</strong>
                                <h3 class="mb-0">Smart Pay formó parte de EXPOCOMER 2023</h3>
                                <p class="card-text mb-auto">Panamá Convention Center, ubicada en amador, fue la sede para EXPOCOMER 2023, albergo a más de 20,000 visitantes nacionales e internacionales.</p>
                                <a
                                    class="stretched-link"
                                    onClick={() => showPosts("Post4")}
                                    style={{ display: showButton }}
                                >
                                    Continuar leyendo
                                </a>
                            </div>
                            <div class="col-auto d-none d-lg-block">
                                <img src={blog_12} class="bd-placeholder-img img-blog-banner h-100" alt="..." width="200" height="250" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                            <div class="col p-4 d-flex flex-column position-static">
                                <strong class="d-inline-block mb-2 text-success">Smart Pay</strong>
                                <h3 class="mb-0">Smart pay está presente en el Panama Convention Center</h3>
                                <p class="mb-auto">{paragraph_post3_1}...</p>
                                <a
                                    class="stretched-link"
                                    onClick={() => showPosts("Post3")}
                                    style={{ display: showButton }}
                                >
                                    Continuar leyendo
                                </a>
                            </div>
                            <div class="col-auto d-none d-lg-block">
                                <img src={card_banner_3} class="bd-placeholder-img img-blog-banner h-100" alt="..." width="200" height="250" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                            <div class="col p-4 d-flex flex-column position-static">
                                <strong class="d-inline-block mb-2 text-success">Smart Pay</strong>
                                <h3 class="mb-0">Llego a Panamá las máquinas de autoservicio Smart Pay .</h3>
                                <p class="mb-auto">
                                    Smart Pay son plataformas de autoservicio, que próximamente estarán en todas las sucursales, les permitieron transformar por completo la experiencia del cliente y reducir significativamente...
                                </p>
                                <a
                                    class="stretched-link"
                                    onClick={() => showPosts("Post2")}
                                    style={{ display: showButton }}
                                >
                                    Continuar leyendo
                                </a>
                            </div>
                            <div class="col-auto d-none d-lg-block">
                                <img src={blog_5} class="bd-placeholder-img img-blog-banner h-100" alt="..." width="200" height="250" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                            <div class="col p-4 d-flex flex-column position-static">
                                <strong class="d-inline-block mb-2 text-primary">BellaBellaBot</strong>
                                <h3 class="mb-0">Implementación del robot Bella en el restaurante Don Lee.</h3>
                                <p class="mb-auto">{paragraph_1}...</p>
                                <a
                                    class="stretched-link"
                                    onClick={() => showPosts("Post1")}
                                    style={{ display: showButton }}
                                >
                                    Continuar leyendo
                                </a>
                            </div>
                            <div class="col-auto d-none d-lg-block">
                                <img src={card_banner} class="bd-placeholder-img img-blog-banner h-100" alt="..." width="200" height="250" />
                            </div>
                        </div>
                    </div>

                </div>
            </RenderIf>

            <RenderIf isTrue={option.option === "Post1"}>

                <div className='mt-3'>
                    <button className='btn btn-outline-secondary border-0' onClick={() => showPosts("Principal")}><BiArrowBack /> Volver </button>

                </div>

                <div class="row g-5 mt-lg-2">
                    <div class="col-md-12">
                        <article class="blog-post">
                            <h2 class="blog-post-title mb-1 h1 fw-bold">Implementación del robot Bella en el restaurante Don Lee</h2>
                            <div class="blog-post-meta d-flex gap-2 mb-4">
                                <span class="badge text-bg-primary"><BiCalendarEdit /> Diciembre 12, 2022</span>
                                <span class="badge text-bg-primary"><TfiWrite /> <span> Dpto. Marketing</span></span>
                                <span class="badge text-bg-primary"> <BiCategoryAlt /> <span> Ctg. Robots</span></span>
                            </div>
                            <p>
                                {paragraph_1}
                            </p>

                            <p>
                                {paragraph_2}
                            </p>

                        </article>
                        <div class="pb-3">
                            {/* <div class="card-img-overlay">
                            <h5 class="card-title">Card title</h5>
                            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                            <p class="card-text"><small>Last updated 3 mins ago</small></p>
                        </div> */}
                        </div>
                        <div className='d-flex justify-content-center mb-4'>
                            <iframe className='rounded' width="760" height="415" src="https://www.youtube.com/embed/yerS_jUDzyI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <p>
                            {paragraph_3}
                        </p>

                        <p>
                            {paragraph_4}
                        </p>

                        <div className='my-4 text-center'>
                            <Carousel interval={4000}>
                                <Carousel.Item>
                                    <img src={blog_1} class="card-img-carousel  rounded" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_2} class="card-img-carousel  rounded" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_3} class="card-img-carousel  rounded" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_4} class="card-img-carousel rounded" alt="..." />
                                </Carousel.Item>
                            </Carousel>

                        </div>


                        <p>
                            {paragraph_5}
                        </p>

                        <p>
                            {paragraph_6}
                        </p>

                        <p>
                            {paragraph_7}
                        </p>
                    </div>
                </div>
            </RenderIf>


            <RenderIf isTrue={option.option === "Post2"}>
                <div className='mt-3'>
                    <button className='btn btn-outline-secondary border-0' onClick={() => showPosts("Principal")}><BiArrowBack /> Volver </button>

                </div>
                <div class="row g-5 mt-lg-2">
                    <div class="col-md-12">
                        <article class="blog-post">
                            <h2 class="blog-post-title mb-1 h1 fw-bold">Llego a Panamá, en colaboración con el restaurante Don Lee, las máquinas de autoservicio Smart Pay.</h2>
                            <div class="blog-post-meta d-flex gap-2 mb-4">
                                <span class="badge text-bg-success"><BiCalendarEdit /> Enero 20, 2023</span>
                                <span class="badge text-bg-success"><TfiWrite /> <span> Dpto. Marketing</span></span>
                                <span class="badge text-bg-success"> <BiCategoryAlt /> <span>Smart Pay</span></span>
                            </div>
                            <p>
                                {paragraph_post2_1}.
                            </p>
                            <p>
                                {paragraph_post2_2}
                            </p>

                            <p>
                                {paragraph_post2_3}
                            </p>

                        </article>
                        <div class="pb-3">
                        </div>
                        <div className='d-flex justify-content-center mb-4'>
                            <iframe className='rounded' width="760" height="415" src="https://www.youtube.com/embed/xe8Sc4ANLWw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <p>
                            {paragraph_post2_4}
                        </p>

                        <p>
                            {paragraph_post2_5}
                        </p>

                        <div className='my-4 text-center'>
                            <Carousel interval={4000}>
                                <Carousel.Item>
                                    <img src={blog_5} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_6} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_7} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_8} class="card-img-carousel rounded h-100 w-100" alt="..." />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>

            </RenderIf>


            <RenderIf isTrue={option.option === "Post3"}>
                <div className='mt-3'>
                    <button className='btn btn-outline-secondary border-0' onClick={() => showPosts("Principal")}><BiArrowBack /> Volver </button>

                </div>
                <div class="row g-5 mt-lg-2">
                    <div class="col-md-12">
                        <article class="blog-post">
                            <h2 class="blog-post-title mb-1 h1 fw-bold">Smart pay está presente en el Panama Convention Center.</h2>
                            <div class="blog-post-meta d-flex gap-2 mb-4">
                                <span class="badge text-bg-success"><BiCalendarEdit /> Marzo 25, 2023</span>
                                <span class="badge text-bg-success"><TfiWrite /> <span> Dpto. Marketing</span></span>
                                <span class="badge text-bg-success"> <BiCategoryAlt /> <span>Smart Pay</span></span>
                            </div>
                            <p>
                                {paragraph_post3_2}
                            </p>

                            <p>
                                {paragraph_post3_3}
                            </p>

                        </article>
                        <div class="pb-3">
                        </div>
                        <div className='d-flex justify-content-center mb-4'>

                            <iframe width="760" height="415" src="https://www.youtube.com/embed/dNjWVCxHnns" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <p>
                            {paragraph_post3_4}
                        </p>

                        <p>
                            {paragraph_post3_5}
                        </p>

                        <div className='my-4 text-center'>
                            <Carousel interval={4000}>
                                <Carousel.Item>
                                    <img src={blog_9} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_10} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_11} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>

            </RenderIf>

            <RenderIf isTrue={option.option === "Post4"}>
                <div className='mt-3'>
                    <button className='btn btn-outline-secondary border-0' onClick={() => showPosts("Principal")}><BiArrowBack /> Volver </button>

                </div>
                <div class="row g-5 mt-lg-2">
                    <div class="col-md-12">
                        <article class="blog-post">
                            <h2 class="blog-post-title mb-1 h1 fw-bold">Smart Pay formó parte de EXPOCOMER 2023</h2>
                            <div class="blog-post-meta d-flex gap-2 mb-4">
                                <span class="badge text-bg-success"><BiCalendarEdit /> Abril 05, 2023</span>
                                <span class="badge text-bg-success"><TfiWrite /> <span> Dpto. Marketing</span></span>
                                <span class="badge text-bg-success"> <BiCategoryAlt /> <span>Smart Pay</span></span>
                            </div>
                            <p>
                                Panamá Convention Center, ubicada en amador, fue la sede para EXPOCOMER 2023, albergo a más de 20,000 visitantes nacionales e internacionales. Debido a que se esperaba una gran asistencia, el Panamá Convention Center vio una oportunidad de mejorar la experiencia y las operaciones de los clientes al pedir comida con el uso de la tecnología en su propio sitio.
                                <br />
                                Con tres puntos de venta de alimentos y bebidas estratégicamente ubicados en todo el centro para que los usuarios elijan, así instalamos nuestro sistema de pedidos interactivo digital para sus clientes.
                            </p>


                        </article>
                        <div class="pb-3">
                        </div>
                        <div className='d-flex justify-content-center mb-4'>
                            <iframe width="760" height="415" src="https://www.youtube.com/embed/Q1iEsE35b64" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        La lógica detrás de una gran participación de usuarios es que se es propenso a las colas, especialmente durante las horas pico, por lo que el quiosco ayuda a agilizar el proceso de pedido y reduce los tiempos de espera.
                        <br />
                        Esto colabora en que el personal ahora es mucho más móvil, ayuda a los clientes con sus consultas, mantiene a los puntos de comida en su mejor aspecto e incluso ofrece un servicio más rápido. Este último puede no parecer revolucionario, pero ha mejorado drásticamente la experiencia gastronómica de los clientes con cierto apuro o aquellos que necesitan un cuidado especial. Ahora, en lugar de intentar hacer cola y mantener un ojo en su mesa, su pedido se puede hacer de forma rápida y sencilla.


                        <div className='my-4 text-center'>
                            <Carousel interval={4000}>
                                <Carousel.Item>
                                    <img src={blog_12} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_13} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_14} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={blog_15} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={blog_16} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </RenderIf>


            <RenderIf isTrue={option.option === "Post5"}>
                <div className='mt-3'>
                    <button className='btn btn-outline-secondary border-0' onClick={() => showPosts("Principal")}><BiArrowBack /> Volver </button>
                </div>
                <div class="row g-5 mt-lg-2">
                    <div class="col-md-12">
                        <article class="blog-post">
                            <h2 class="blog-post-title mb-1 h1 fw-bold">BellaBella Bot llego a Burger King</h2>
                            <div class="blog-post-meta d-flex gap-2 mb-4">
                                <span class="badge text-bg-primary"><BiCalendarEdit />Mayo 12, 2023</span>
                                <span class="badge text-bg-primary"><TfiWrite /> <span> Dpto. Marketing</span></span>
                                <span class="badge text-bg-primary"> <BiCategoryAlt /> <span>BellaBella Bot</span></span>
                            </div>
                            <p>
                                El robot de delivery BellaBot ha sido implementado en la sucursal de Burger King en El Dorado, en colaboración con Smart Retail Group. Este robot de servicio y asistencia permite a los colaboradores de la sucursal ser más productivos y eficientes, aliviando su carga laboral y ofreciendo una interacción más amistosa e interactiva con los clientes.
                            </p>

                            <p>
                                La innovadora implementación de BellaBot ha llamado la atención gracias a su alta tecnología y su presencia en Panamá, siendo distribuido por Smart Retail Group.
                                <br />
                                Uno de los aspectos más destacados de este robot es su capacidad de interactuar con los clientes mediante gestos y habla, lo que le permite evitar obstáculos mientras lleva los pedidos y saludar a los clientes de forma empática.
                            </p>
                        </article>
                        <div class="pb-3">
                        </div>
                        <div className='d-flex justify-content-center mb-4'>
                            <iframe width="760" height="415" src="https://www.youtube.com/embed/WPpyUghbrdk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <p>
                            Los colaboradores de Burger King han tenido una experiencia positiva al trabajar con BellaBot, ya que ha permitido agilizar el despacho de pedidos de forma fácil y muchos clientes disfrutan interactuar con ella.
                            <br />
                            El colaborador le indica en qué mesa se encuentra el cliente y ella se dirige hacia allá. Esta tecnología es una herramienta para mejorar la atención y experiencia de los clientes.
                        </p>

                        <div className='my-4 text-center'>
                            <Carousel interval={4000}>
                                <Carousel.Item>
                                    <img src={blog_17} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_18} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_19} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={blog_20} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={blog_21} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </RenderIf>

            <RenderIf isTrue={option.option === "Post6"}>
                <div className='mt-3'>
                    <button className='btn btn-outline-secondary border-0' onClick={() => showPosts("Principal")}><BiArrowBack /> Volver </button>
                </div>
                <div class="row g-5 mt-lg-2">
                    <div class="col-md-12">
                        <article class="blog-post">
                            <h2 class="blog-post-title mb-1 h1 fw-bold">Implementación de Smart Pay Post de Pago en el Panama Convention Center</h2>
                            <div class="blog-post-meta d-flex gap-2 mb-4">
                                <span class="badge text-bg-primary"><BiCalendarEdit />Junio 26, 2023</span>
                                <span class="badge text-bg-primary"><TfiWrite /> <span> Dpto. Marketing</span></span>
                                <span class="badge text-bg-primary"> <BiCategoryAlt /> <span>Smart Pay</span></span>
                            </div>
                            <p>
                                {paragraph_post6_1}
                                <br />
                                {paragraph_post6_2}
                                <br />
                                {paragraph_post6_3}
                            </p>
                            <p>
                                {paragraph_post6_4}
                                <br />
                                {paragraph_post6_5}
                            </p>
                        </article>
                        <div class="pb-3">
                        </div>
                        <div className='my-4 text-center'>
                            <Carousel interval={4000}>
                                <Carousel.Item>
                                    <img src={blog_22} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_23} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img src={blog_24} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={blog_25} class="card-img-carousel  rounded h-100 w-100" alt="..." />
                                </Carousel.Item>                     
                            </Carousel>
                        </div>
                        <p>
                            {paragraph_post6_6}
                            <br />

                            {paragraph_post6_7}
                        </p>


                    </div>
                </div>
            </RenderIf>
        </>
    )
}

export default BlogContent