import React from 'react'

import imageLogo from '../img/imgSmartpay/logo.png'

import SmartPayAll from '../img/imgSmartpay/SmartPayAll.png'
import ProcesadorDePago from '../img/imgSmartpay/ProcesadorDePago.png'
import Cajero from '../img/imgSmartpay/Cajero.png'
import ScanAndGo from '../img/imgSmartpay/ScanAndGo.png'

function Center({ title, description, image }) {
    return (
        <>
            <section className=" main-banner" id="scroll-target">

                <div class="slideauto pt-5  mt-5">
                    <div >
                        <img className='imginfinite' alt="#" src={SmartPayAll} />
                    </div>
                    <div>
                        <img className='imginfinite' alt="#" src={ProcesadorDePago} />
                    </div>
                    <div >
                        <img className='imginfinite' alt="#" src={imageLogo} />
                    </div>
                    <div >
                        <img className='imginfinite' alt="#" src={Cajero} />
                    </div>
                    <div >
                        <img className='imginfinite' alt="#" src={ScanAndGo} />
                    </div>
                    <div >
                        <img className='imginfinite' alt="#" src={SmartPayAll} />
                    </div>
                    <div>
                        <img className='imginfinite' alt="#" src={ProcesadorDePago} />
                    </div>
                    <div >
                        <img className='imginfinite' alt="#" src={imageLogo} />
                    </div>
                    <div >
                        <img className='imginfinite' alt="#" src={Cajero} />
                    </div>
                    <div >
                        <img className='imginfinite' alt="#" src={ScanAndGo} />
                    </div>

                </div>
                <div className="container  py-5  ">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-12 text-center">
                        
                        <h1 className="fw-bolder gradiant-sp m-3 text-center">{title}</h1>
                        <p className="lead fw-normal text-muted mb-0">{description}</p>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Center