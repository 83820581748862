import React, { useState, useEffect } from 'react'

//Components 
import Loader from './Bella/Loader'
import Hero from './Bella/Hero';
//Bostrap
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ContactForm from './Forms/ContactForm';
//CSS
import './Bella/css/bella.css'
import About from './Bella/About';
import HelpU from './Bella/HelpU';
import Adaptation from './Bella/Adaptation';
import Desing from './Bella/Desing';
import Features from './Bella/Features';
import Footer from './Footer/Footer';


function _index_2() {
  const [loader, setLoader] = useState(true);
  useEffect(()=> {
    setTimeout(() => {
      setLoader(false);
    }, 5000);
    
  }, [])
  return loader ? (
    <div className='app'>
      <div className='app-header'>
        <Loader /> 
      </div>
    </div>
  )
    :
    (
      <>
        {/* Nav Bar */}
        <Hero/>
        <About/>
        <HelpU/>
        <Adaptation/>
        <Desing/>
        {/* <Features/> */}
        <div className="container_smartpay" >
                <ContactForm />
          </div>
        <Footer/>
      </>
    )
}

export default _index_2