import React from 'react'
import visual_bella from './video/visual.mp4'

function Adaptation() {
	return (
		<>
			<section id="adaptation" className="adaptation-section pt-70  pb-50">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-12">
							<div className="adaptation-content pb-4">
								<div className="section-title">
									<h2 className="h1 text-center text-white fw-bold  mb-35">Solución SLAM (simultaneous localization and mapping) doble exclusiva de la industria para una cobertura total en cualquier escenario</h2>
								</div>
							</div>
						</div>
						<div className="col-lg-12 ">
							<div className="adaptation-video">
								<video autoPlay muted loop src={visual_bella} type="video/mp4"  ></video>
							</div>
							{/* <p className="mt-20 mb-35 fs-4">
								BellaBot es compatible con las soluciones de posicionamiento y navegación SLAM con láser y SLAM visual para adaptarse a más escenarios.Ambas son precisas y fáciles de usar.Las dos soluciones de posicionamiento que ofrece BellaBot brindan la misma excelente experiencia de usuario.
							</p> */}
						</div>
					</div>
				</div>
			</section>


		</>
	)
}

export default Adaptation