import React from 'react'



function LeftBanner({bannerImage, pageName, titlePage, description }) {
    return (
        <>
            <div className="main-banner" id="top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-6 align-self-center">
                                    <div className="right-image" >
                                        <img src={bannerImage} className="img-fluid" alt="" />
                                    </div>

                                </div>
                                <div className="col-lg-6">
                                    <div className="left-content show-up header-text" >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h6>{pageName} </h6>
                                                <h2 className='gradiant-sp' >{titlePage}</h2>
                                                <p className="lead fw-normal text-muted mb-0">{description}</p>
                                            </div>
                                            {/* <div className="col-lg-12">
                    <div className="border-first-button scroll-to-section">
                      <a href="#contact">Free Quote</a>
                    </div>
                  </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LeftBanner