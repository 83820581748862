import React from 'react'

function Desing() {
  return (
    <>



<section id="desing" className="desing-section pt-70 ">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="desing-content pb-4">
								<div className="section-title">
									<h2 className="h1 text-white fw-bold  mb-35">
                                        Cada robot puede comunicarse directamente 
                                        con cualquier robot de la misma red para 
                                        calcular y tomar decisiones rápidamente.
                                    </h2>
   
                                </div>
							</div>
						</div>
						<div className="col-lg-6 ">
							<div className="desing-video">
								<video autoPlay muted loop src=".." type="video/mp4"  ></video>
							</div>
						</div>
					</div>
				</div>
			</section>  
    
    </>
  )
}

export default Desing