import React from 'react'

import logo from '../img/logos/logo-main.png'
import products from '../img/products/srg-products.png'

function Banner() {
  return (
    <>
      <div className=' d-flex justify-content-center '>
        <img src={logo} class="img-logo-sp img-fluid d-block mx-auto" alt="" />
      </div>
      <div className="main-banner" id="top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 align-self-center">
                  <div className="left-content show-up header-text" >
                    <div className="row">
                      <div className="col-lg-12">
                        <h6>Smart Retail Group </h6>
                        <h2 className='gradiant-srg' >Implementamos soluciones de calidad</h2>
                        <p>Una empresa dedicada a la innovación y desarrollo de nuevas tecnologías, con el propósito de implementar soluciones novedosas a problemas tanto empresariales como a nivel individual.</p>
                      </div>

                      {/* <div className="col-lg-12">
                    <div className="border-first-button scroll-to-section">
                      <a href="#contact">Free Quote</a>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="right-image" >
                    <img src={products} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Banner