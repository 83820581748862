import React from 'react'
import { Link } from 'react-router-dom';
//Utils 
import { RenderIf } from '../../utils/RenderIf';

function Right({ title, description, image, buttonLink }) {
    return (
        <>
            <section className=" bg-gr py-5">
                <div className="container px-5 my-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6 order-first order-lg-last align-items-center">
                            <img className="img-fluid rounded mb-5 mb-lg-0 " src={image} alt="..." />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="fw-bolder gradiant-sp">{title}</h2>
                            <p className="lead fw-normal text-muted mb-0 ">{description}</p>
                            {/** Button */}
                            <RenderIf isTrue={buttonLink === '' || buttonLink !== undefined}>
                                <div className="col-lg-12">
                                    <div className="border-first-button scroll-to-section">
                                        <Link className='btn btn-primary py-2 px-4 mt-4' to={buttonLink}>Mas información</Link>
                                    </div>
                                </div>
                            </RenderIf>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Right