import React from 'react'
import BellaGif from './img/load/bella-load.gif'
function Loader() {
  return (
    <>
      <div className=' animate__animated animate__fadeIn animate__delay-1s'>
        <img src={BellaGif} alt="Animacion Bella" className='img-bella' />
        <div className='text-white mt-5'>
          <h3 className='text-white'>Miau!</h3>
          <h4 className='text-white '>Bienvenido</h4>
        </div>
      </div>
    </>
  )
}

export default Loader